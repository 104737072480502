import { AnimatePresence } from "framer-motion";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { AnimateHeightMotion } from "../../../components/animate/AnimateHeightMotion";
import { Form, FormContainer } from "../../../components/form/Form";
import { hasRealErrors } from "../../../components/form/FormHelpers";
import { Retry } from "../../../components/retry/Retry";
import { dataContracts } from "../../../data/dataContracts";
import { dataPricing } from "../../../data/dataPricing";
import { Status } from "../../../data/types";
import { PricingModel, PricingStructure, PricingTemplate } from "../../../model/pricing/pricingTypes";
import {
  ACQUIRING_COUNTRIES,
  CASHBACK_COUNTRIES,
  CASHLESS_PROMOTION_COUNTRIES,
  convertToOptionalFee,
  INSTALLATION_FEE_COUNTRIES,
  isPack,
} from "../../../model/pricing/pricingUtils";
import { availablePricingTemplatesState, TEMPLATE_ID_MOCK } from "../../../state/contractCostState";
import { contractStatusState } from "../../../state/contractStatusState";
import PackData from "../../Admin/Pricing/AdminPricingCountryPage/AdminPresetPage/PricingInputs/PackData/PackData";
import SteppedBasedPricingAdmin from "../../Admin/Pricing/AdminPricingCountryPage/AdminPresetPage/PricingInputs/SteppedBasedPricing/SteppedBasedPricingAdmin";
import TransactionFeeInputs from "../../Admin/Pricing/AdminPricingCountryPage/AdminPresetPage/PricingInputs/TransactionFeeInputs/TransactionFeeInputs";
import { ContractEditError } from "../ContractEditError";
import { FormName } from "../menus/ContractEditMenu";
import { AdditionalSettings } from "./AdditionalSettings/AdditionalSettings";
import { CashlessPromotion } from "./CashlessPromotion/CashlessPromotion";
import { FeeValidation } from "./FeeValidation";
import "./Pricing.scss";
import { PricingIngress } from "./PricingIngress/PricingIngress";
import { PromotionMonths } from "./PromotionMonths/PromotionMonths";
import { AddTerminalsToStores } from "./stores/Store/AddTerminalsToStores";
import { StorePack } from "./stores/StorePack/StorePack";
import { LoadTemplate } from "./templates/LoadTemplate";
import useSavePricingStructure from "./useSavePricingStructure";

import ContentBox from "../../../components/ContentBox/ContentBox";
import { ContractType } from "../../../model/contract/contractType";
import { getContractTypeDisplayString } from "../../../model/contract/contractUtils";
import PomcaFee from "../../Admin/Pricing/AdminPricingCountryPage/AdminPresetPage/PricingInputs/PomcaFee/PomcaFee";
import StoreTerminalLimitation from "../../Admin/Pricing/AdminPricingCountryPage/AdminPresetPage/PricingInputs/StoreTerminalLimitation/StoreTerminalLimitation";
import { Acceptance } from "./Acceptance/Acceptance";
import styles from "./AdvancedPricing.module.scss";
import ArticlesTable from "./ArticlesTable/ArticlesTable";
import { Cashback } from "./Cashback/Cashback";
import { InstallationFee } from "./InstallationFee/InstallationFee";
import { LimitationValidation } from "./LimitationValidation";
import PomcaFeeSummary from "./PomcaFeeSummary/PomcaFeeSummary";

export const PricingInstore: React.FunctionComponent = () => {
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const { contractId, edit, country } = useRecoilValue(contractStatusState);
  const [templates, setTemplates] = useRecoilState(availablePricingTemplatesState);

  const { savePricingStructure, contractPricing, error, setError, setPricingView } =
    useSavePricingStructure();

  const {
    pricingModel,
    transactionFees,
    view,
    templateId: contractTemplateId,
    steppedBasedPricing,
    storeLimitation,
    terminalLimitation,
    monthlyAcquiringFee,
    monthlyTurnoverThreshold,
    acceptance,
    pomca,
    terminalType,
    name,
  } = contractPricing.INSTORE;

  const optionalTransactionFees = useMemo(() => transactionFees.map(convertToOptionalFee), [transactionFees]);
  // const optionalSteppedBasedPricing = useMemo(
  //   () => steppedBasedPricing && convertToOptionalSteppedPricing(steppedBasedPricing),
  //   [steppedBasedPricing]
  // );
  const disabled = !edit || true;
  const IsNotMockId = contractTemplateId !== TEMPLATE_ID_MOCK;

  const formRef = useRef<FormContainer>();
  const transactionFeesRef = useRef<HTMLDivElement>(null);
  const terminalPricesRef = useRef<HTMLDivElement>(null);

  const fetchPricingTemplates = useCallback(
    async (clearCache: boolean = false) => {
      try {
        const templates = await dataPricing.loadTemplates(contractId, clearCache);
        const sortedTemplates = [...templates].sort(sortByPriority);
        setTemplates(sortedTemplates);
        setStatus(Status.SUCCESS);
      } catch (err) {
        console.log("Could not fetch templates: ", err);
        setStatus(Status.ERROR);
      }
    },
    [contractId, setTemplates]
  );

  useEffect(() => {
    if (typeof formRef.current === "undefined") return;
    if (contractPricing.INSTORE.templateId === -1) return;
    if (!contractPricing.INSTORE.pricingModel) return;
    savePricingStructure(contractPricing.INSTORE);
  }, [contractPricing.INSTORE, savePricingStructure]);

  useEffect(() => {
    if (status !== Status.DEFAULT) return;
    setStatus(Status.PENDING);
    fetchPricingTemplates();
  }, [fetchPricingTemplates, status]);

  const reclaimAndSave = useCallback(() => {
    setError(null);
    setStatus(Status.PENDING);
    setTimeout(() => {
      dataContracts
        .claimContract(contractId)
        .then(() => savePricingStructure(contractPricing.INSTORE))
        .catch(savePricingStructure); // We're lazy, execute save again, which will fail, and propagate error to Overlay
    }, 500);
  }, [contractId, savePricingStructure, contractPricing, setError]);

  const retry = useCallback(() => {
    setError(null);
    setStatus(Status.PENDING);
    setTimeout(() => fetchPricingTemplates(true), 3000);
  }, [fetchPricingTemplates, setError]);

  if (
    status === Status.SUCCESS &&
    isMissingTemplatesAndMissingPricingInContract(templates, contractPricing.INSTORE)
  )
    return <div>Success but is missing stuff</div>;

  return (
    <div className="pricing">
      <ContractEditError
        error={error}
        setError={setError}
        retry={retry}
        onClose={() => setError(null)}
        reclaimAndSave={reclaimAndSave}
      />
      <h5>{getContractTypeDisplayString(ContractType.INSTORE)}</h5>

      <ContentBox className={styles.instore} collapsible>
        <PricingIngress />

        <Retry retry={retry} status={status}>
          <AnimateHeightMotion>
            <Form
              onSaveTrigger={(event, form) => {
                if (form.isInvalid || hasRealErrors(form)) return;
                savePricingStructure(contractPricing.INSTORE);
              }}
              name={FormName.PRICING_CALCULATOR}
              formContainer={formRef}
            >
              <LoadTemplate contractTypeTemplatesToLoad={ContractType.INSTORE} />

              {IsNotMockId && (
                <>
                  <AnimateHeightMotion presence>
                    {pricingModel === PricingModel.PACK && (
                      <PackData
                        key="pack-data"
                        monthlyAcquiringFee={monthlyAcquiringFee}
                        monthlyTurnoverThreshold={monthlyTurnoverThreshold}
                        disabled={true}
                        country={country}
                        isSalesComponent
                        terminalType={terminalType}
                      />
                    )}

                    {(storeLimitation || terminalLimitation) && (
                      <>
                        <StoreTerminalLimitation
                          terminalLimitationEnabled={!!terminalLimitation}
                          terminalLimitation={terminalLimitation}
                          storeLimitationEnabled={!!storeLimitation}
                          storeLimitation={storeLimitation}
                          isSalesComponent
                          pomcaIsEnabled={true}
                        />
                        <LimitationValidation
                          storeLimitation={storeLimitation}
                          terminalLimitation={terminalLimitation}
                          contractType={ContractType.INSTORE}
                        />
                      </>
                    )}

                    {!!pomca && <PomcaFee pomcas={pomca} updatePricing={() => null} isSalesComp />}
                  </AnimateHeightMotion>
                  <AnimatePresence>
                    {steppedBasedPricing ? (
                      <SteppedBasedPricingAdmin
                        key={"stepped-based-pricing"}
                        steppedBasedPricing={steppedBasedPricing}
                        country={country}
                        pricingModel={pricingModel}
                        setEditablePricing={() => console.log("")}
                        isSalesComp
                      />
                    ) : (
                      <TransactionFeeInputs
                        key={"transaction-fees"}
                        pricingView={view}
                        pricingModel={pricingModel}
                        optionalTransactionFees={optionalTransactionFees}
                        disabled={disabled}
                        roundedBorders={false}
                        country={country}
                        setPricingView={(view) => setPricingView(view, ContractType.INSTORE)}
                        className="m-bottom-30"
                        isSalesComponent={true}
                        priceplanName={name}
                      />
                    )}
                  </AnimatePresence>

                  <FeeValidation
                    transactionFees={optionalTransactionFees}
                    scrollRef={transactionFeesRef}
                    country={country}
                  />

                  <AdditionalSettings
                    disabled={disabled}
                    isSalesComponent={true}
                    country={country}
                    {...contractPricing.INSTORE}
                  />

                  {CASHLESS_PROMOTION_COUNTRIES.includes(country) ? (
                    <CashlessPromotion contractType={ContractType.INSTORE} />
                  ) : (
                    <PromotionMonths contractType={ContractType.INSTORE} />
                  )}
                  {CASHBACK_COUNTRIES.includes(country) && <Cashback />}

                  {ACQUIRING_COUNTRIES.includes(country) && <Acceptance />}

                  <AnimateHeightMotion presence>
                    {acceptance && (
                      <>
                        {IsNotMockId && (
                          <div ref={terminalPricesRef}>
                            {isPack(pricingModel) ? <StorePack /> : <AddTerminalsToStores />}
                          </div>
                        )}

                        {INSTALLATION_FEE_COUNTRIES.includes(country) && <InstallationFee />}
                        <ArticlesTable />
                        {!!pomca && <PomcaFeeSummary pomca={pomca} />}
                      </>
                    )}
                  </AnimateHeightMotion>
                </>
              )}
            </Form>
          </AnimateHeightMotion>
        </Retry>
      </ContentBox>
    </div>
  );
};

const sortByPriority = (a: PricingTemplate, b: PricingTemplate) => {
  if (a.priority > b.priority) {
    return 1;
  }

  if (a.priority < b.priority) {
    return -1;
  }

  return a.name.localeCompare(b.name);
};

function isMissingTemplatesAndMissingPricingInContract(templates: PricingTemplate[], cost: PricingStructure) {
  if (cost.templateId !== -1) {
    return false;
  }

  if (templates.length > 0) {
    return false;
  }

  return true;
}
