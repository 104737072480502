import { Dispatch, FC, SetStateAction, memo, useCallback, useEffect, useState } from "react";
import { AnimateHeightMotion } from "../../../../../../components/animate/AnimateHeightMotion";
import { Language } from "../../../../../../i18n";
import { Country } from "../../../../../../model/common/commonType";
import {
  DynamicPricingFields,
  EditablePricingTemplate,
  PricingModel,
  PricingView,
} from "../../../../../../model/pricing/pricingTypes";
import { AdditionalSettings } from "../../../../../Contract/pricingV2/AdditionalSettings/AdditionalSettings";
import CashlessPromotionAdmin from "./CashlessPromotionAdmin/CashlessPromotionAdmin";
import DisableTerminals from "./DisableTerminals/DisableTerminals";
import { GeneralPricePlanDataInputs } from "./GeneralPricePlanInputs/GeneralPricePlanDataInputs";
import { IpassMapping } from "./IpassMapping/IpassMapping";
import PackData from "./PackData/PackData";
import PomcaFee from "./PomcaFee/PomcaFee";
import styles from "./PricingInputs.module.scss";
import SteppedBasedPricingAdmin from "./SteppedBasedPricing/SteppedBasedPricingAdmin";
import StoreTerminalLimitation from "./StoreTerminalLimitation/StoreTerminalLimitation";
import TariffClassMapping from "./TariffClassMapping/TariffClassMapping";
import TransactionFeeInputs from "./TransactionFeeInputs/TransactionFeeInputs";

export type SetEditablePricing = Dispatch<SetStateAction<EditablePricingTemplate>>;

interface Props {
  isUpdate: boolean;
  editablePricing: EditablePricingTemplate;
  setEditablePricing: SetEditablePricing;
  lang: Language;
  country: Country;
  disabled?: boolean;
}

export type PricingUpdateType = Partial<EditablePricingTemplate>;

export const PricingInputs: FC<Props> = ({
  isUpdate,
  editablePricing,
  lang,
  country,
  setEditablePricing,
  disabled = false,
}) => {
  const [storeLimitationEnabled, setStoreLimitationEnabled] = useState(false);
  const [terminalLimitationEnabled, setTerminalLimitationEnabled] = useState(false);
  const [disableTerminalsEnabled, setDisableTerminalsEnabled] = useState(false);

  const {
    pricingModel,
    steppedBasedPricing,
    name,
    description,
    conditionCategoryCode,
    contractCategory,
    contractTemplateName,
    terminalType,
    tariffClassMappings,
    monthlyAcquiringFee,
    monthlyTurnoverThreshold,
    view,
    transactionFees,
    surchargesCommercialCards,
    surchargesNonEea,
    chargebackFee,
    preAuthorizationFees,
    dcc,
    cashlessPromotion,
    cashlessPromotionIsPreSelected,
    contractType,
    storeLimitation,
    terminalLimitation,
    disabledTerminals,
    pomca,
  } = editablePricing;

  useEffect(() => {
    // Unfortunatley we cannot initialize these value from the start in the useState, so we need an useEffect for this
    if (storeLimitation) setStoreLimitationEnabled(true);
    if (terminalLimitation) setTerminalLimitationEnabled(true);
    if (disabledTerminals) setDisableTerminalsEnabled(true);
  }, [storeLimitation, terminalLimitation, disabledTerminals]);

  const disableInputs = disabled || !pricingModel;
  const isPack = pricingModel === PricingModel.PACK;

  const update = useCallback(
    (updates: PricingUpdateType) => {
      setEditablePricing((prev) => {
        const copy = { ...prev, ...updates };
        const dynamicFields = validateDynamicFields(copy);
        return {
          ...copy,
          ...dynamicFields,
        };
      });
    },
    [setEditablePricing]
  );

  const setPricingView = useCallback(
    (view: PricingView) => {
      setEditablePricing((prev) => ({
        ...prev,
        view,
      }));
    },
    [setEditablePricing]
  );

  return (
    <div className={styles.root}>
      <GeneralPricePlanDataInputsMemo
        isUpdate={isUpdate}
        language={lang}
        country={country}
        disabled={disabled}
        update={update}
        pricingModel={pricingModel}
        steppedBasedPricing={steppedBasedPricing}
        name={name}
        description={description}
        cashlessPromotion={cashlessPromotion}
        cashlessPromotionIsPreSelected={cashlessPromotionIsPreSelected}
        contractType={contractType}
        storeLimitationIsEnabled={storeLimitationEnabled}
        setStoreLimitationIsEnabled={setStoreLimitationEnabled}
        terminalLimitationIsEnabled={terminalLimitationEnabled}
        setTerminalLimitationIsEnabled={setTerminalLimitationEnabled}
        disableTerminalsEnabled={disableTerminalsEnabled}
        setDisableTerminalsEnabled={setDisableTerminalsEnabled}
        pomca={pomca}
      />

      <AnimateHeightMotion presence>
        {!isPack && (storeLimitationEnabled || terminalLimitationEnabled) && (
          <StoreTerminalLimitation
            storeLimitationEnabled={storeLimitationEnabled}
            terminalLimitationEnabled={terminalLimitationEnabled}
            storeLimitation={storeLimitation}
            terminalLimitation={terminalLimitation}
            updatePricing={update}
            pomcaIsEnabled={!!pomca}
          />
        )}
      </AnimateHeightMotion>

      <AnimateHeightMotion presence>
        {!!pomca && <PomcaFee pomcas={pomca} updatePricing={update} disabled={disableInputs} />}
      </AnimateHeightMotion>

      <AnimateHeightMotion presence>
        {disableTerminalsEnabled && disabledTerminals && (
          <DisableTerminals
            disabled={disableInputs}
            country={country}
            disabledTerminalTypes={disabledTerminals}
            updatePricing={update}
          />
        )}
      </AnimateHeightMotion>

      <AnimateHeightMotion presence>
        {cashlessPromotionIsPreSelected && (
          <CashlessPromotionAdmin
            disabled={disableInputs}
            key="cashless-promotion"
            cashlessPromotion={cashlessPromotion}
            setEditablePricing={setEditablePricing}
            country={country}
          />
        )}
      </AnimateHeightMotion>

      <AnimateHeightMotion presence>
        {isPack && (
          <PackDataMemo
            disabled={disabled}
            country={country}
            terminalType={terminalType}
            monthlyAcquiringFee={monthlyAcquiringFee}
            monthlyTurnoverThreshold={monthlyTurnoverThreshold}
            update={update}
          />
        )}
      </AnimateHeightMotion>

      {steppedBasedPricing ? (
        <SteppedBasedPricingAdminMemo
          pricingModel={pricingModel || PricingModel.BLENDED}
          steppedBasedPricing={steppedBasedPricing}
          setEditablePricing={setEditablePricing}
          country={country}
          disabled={disableInputs}
        />
      ) : (
        <>
          <TransactionFeeInputsMemo
            pricingModel={pricingModel || PricingModel.BLENDED}
            pricingView={view}
            optionalTransactionFees={transactionFees}
            setPricingView={setPricingView}
            setEditablePricing={setEditablePricing}
            disabled={disableInputs}
            country={country}
            priceplanName={name ?? ""}
          />
        </>
      )}
      <AdditionalSettingsMemo
        disabled={disableInputs}
        preAuthorizationFees={preAuthorizationFees}
        surchargesCommercialCards={surchargesCommercialCards}
        surchargesNonEea={surchargesNonEea}
        pricingModel={pricingModel}
        chargebackFee={chargebackFee}
        setEditablePricing={setEditablePricing}
        isSalesComponent={false}
        country={country}
        contractType={contractType}
        dccAdminValues={dcc}
      />
      <IpassMappingMemo
        update={update}
        inputsDisabled={disabled}
        conditionCategoryCode={conditionCategoryCode}
        contractCategory={contractCategory}
        contractTemplateName={contractTemplateName}
      />
      <TariffClassMappingMemo
        country={country}
        terminalType={terminalType}
        tariffClassMappings={tariffClassMappings}
        setEditablePricing={setEditablePricing}
        disabled={disableInputs}
      />
    </div>
  );
};

const validateDynamicFields = (editablePricing: EditablePricingTemplate): DynamicPricingFields => {
  const { pricingModel } = editablePricing;
  const isBundle = pricingModel === PricingModel.ICPP || pricingModel === PricingModel.BLENDED;
  const isICPP = pricingModel === PricingModel.ICPP;
  const isPack = pricingModel === PricingModel.PACK;

  const fields: DynamicPricingFields = {
    preAuthorizationFees: isICPP ? false : editablePricing.preAuthorizationFees,
    surchargesNonEea: isICPP ? 0 : editablePricing.surchargesNonEea,
    surchargesCommercialCards: isICPP ? 0 : editablePricing.surchargesCommercialCards,

    monthlyAcquiringFee: !isPack ? undefined : editablePricing.monthlyAcquiringFee,
    monthlyTurnoverThreshold: !isPack ? undefined : editablePricing.monthlyTurnoverThreshold,

    steppedBasedPricing: isPack ? undefined : editablePricing.steppedBasedPricing,

    storeLimitation: isPack ? undefined : editablePricing.storeLimitation,
    terminalLimitation: isPack ? undefined : editablePricing.terminalLimitation,

    terminalType: isBundle ? undefined : editablePricing.terminalType,
  };

  return fields;
};

const GeneralPricePlanDataInputsMemo = memo(GeneralPricePlanDataInputs);
const IpassMappingMemo = memo(IpassMapping);
const TariffClassMappingMemo = memo(TariffClassMapping);
const PackDataMemo = memo(PackData);
const TransactionFeeInputsMemo = memo(TransactionFeeInputs);
const AdditionalSettingsMemo = memo(AdditionalSettings);
const SteppedBasedPricingAdminMemo = memo(SteppedBasedPricingAdmin);
