import React, { Dispatch, FC, SetStateAction, useCallback } from "react";
import { NumberInput } from "../../../../components/form/NumberInput";
import { MinValidator } from "../../../../components/form/validators/MinValidator";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";
import { Country } from "../../../../model/common/commonType";
import {
  CardBrand,
  EditablePricingTemplate,
  FeeType,
  OptionalTransactionFee,
  PricingModel,
} from "../../../../model/pricing/pricingTypes";
import { FeeBrandLogo } from "./FeeBrandLogo";
import {
  FeeInput,
  GetUpdatedFeesArgs,
  UpdateFeeInputArgs,
  getUpdatedFees,
  updateTariffClassInvidiualFee,
} from "./FeeInput";
import "./View.scss";

interface Props {
  disabled?: boolean;
  pricingModel: PricingModel;
  transactionFees: OptionalTransactionFee[];
  setEditablePricing?: Dispatch<SetStateAction<EditablePricingTemplate>>;
  country: Country;
  isSalesComponent?: boolean;
  cardBrandsToHide?: CardBrand[];
}

export const Individual: FC<Props> = ({
  disabled = false,
  pricingModel,
  transactionFees,
  setEditablePricing,
  country,
  isSalesComponent = false,
  cardBrandsToHide = [],
}) => {
  const isActive = !disabled;

  const updateFeeInput = useCallback(
    (updateArgs: UpdateFeeInputArgs) => {
      if (!setEditablePricing) return;
      setEditablePricing((prev) => {
        const args: GetUpdatedFeesArgs = {
          ...updateArgs,
          transactionFees: prev.transactionFees,
        };
        const updatedFees = getUpdatedFees(args);
        return {
          ...prev,
          transactionFees: updatedFees,
        };
      });
    },
    [setEditablePricing]
  );

  const updateTariffClass = (value: number | undefined, cardBrand: CardBrand) => {
    if (!setEditablePricing || value === undefined) return;

    setEditablePricing((prev) => {
      const updatedFees = updateTariffClassInvidiualFee({
        value: value,
        cardBrand,
        transactionFees: prev.transactionFees,
      });

      return {
        ...prev,
        transactionFees: updatedFees,
      };
    });
  };

  return (
    <div className="pricing-view">
      {transactionFees
        .filter((fee) => !cardBrandsToHide.includes(fee.brand))
        .map((fee) => {
          const { brand, tariffClass } = fee;

          return (
            <React.Fragment key={brand}>
              <div className="fee-logo-wrapper" key={brand}>
                <FeeBrandLogo brand={brand as CardBrand} />
              </div>

              <div className="card-column-inputs">
                <div>
                  <FeeInput
                    pricingModel={pricingModel}
                    updateFeeInput={updateFeeInput}
                    item={fee}
                    feeType={FeeType.TRANSACTION}
                    required={false}
                    active={isActive}
                    country={country}
                  />
                </div>

                <div>
                  <FeeInput
                    pricingModel={pricingModel}
                    updateFeeInput={updateFeeInput}
                    item={fee}
                    feeType={FeeType.MIN}
                    required={false}
                    active={isActive}
                    country={country}
                  />
                </div>

                <div>
                  <FeeInput
                    pricingModel={pricingModel}
                    updateFeeInput={updateFeeInput}
                    item={fee}
                    feeType={FeeType.MAX}
                    required={false}
                    active={isActive}
                    country={country}
                  />
                </div>

                <div>
                  <FeeInput
                    pricingModel={pricingModel}
                    updateFeeInput={updateFeeInput}
                    item={fee}
                    feeType={FeeType.FIXED}
                    required={false}
                    active={isActive}
                    country={country}
                  />
                </div>

                {!isSalesComponent && (
                  <div>
                    <NumberInput
                      onChange={(value) => updateTariffClass(value, brand)}
                      label={"Tariff class"}
                      validators={[
                        new RequiredValidator("Tariff class is required"),
                        new MinValidator(1, "Tariff class must be a positive number"),
                      ]}
                      value={tariffClass}
                      message={"Needed for provisioning to iPass"}
                    />
                  </div>
                )}
              </div>
            </React.Fragment>
          );
        })}
      <div style={{ borderTop: "1px solid transparent" }} />
    </div>
  );
};
